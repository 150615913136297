import type { HomeTypeEnum, SavedSearchesQuery } from '@qasa/graphql'
import { HomeTraitEnum } from '@qasa/graphql'
import { useAppTranslation } from '@qasa/app'
import { useRegionConfig } from '@qasa/app/src/configs/use-region-config'
import omit from 'lodash/omit'
import { formatNumber } from '@qasa/app/src/utils/number'

import { FILTERABLE_HOME_TYPES } from '../filters/utils/home-type-mapping'
import { getIsFilterActive } from '../filters/utils/filters.utils'
import type { FindHomeFilterValues } from '../filters/use-find-home-filters'

type ParseSavedSearchParams = {
  searchParams: SavedSearchesQuery['savedSearch'][number]['searchParams']
}

export const parseSavedSearch = ({ searchParams }: ParseSavedSearchParams): Partial<FindHomeFilterValues> => {
  const contractTypes = []

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    searchAreas,
    corporateHome,
    studentHome,
    seniorHome,
    firsthand,
    homeType,
    wheelchairAccessible,
    shared,
    furniture,
    pets,
    smoker,
    minMonthlyCost,
    maxMonthlyCost,
    minRoomCount,
    maxRoomCount,
    maxSquareMeters,
    minSquareMeters,
    moveInEarliest,
    minRentalLength,
    traits,
    householdSize,
  } = searchParams
  /* eslint-enable @typescript-eslint/naming-convention */

  // Contract types
  if (firsthand) contractTypes.push('firstHand')
  if (studentHome) contractTypes.push('studentHome')
  if (seniorHome) contractTypes.push('seniorHome')
  if (corporateHome) contractTypes.push('corporateHome')

  // Shared
  const sharedHome = shared === null ? [] : shared ? ['sharedHome'] : ['privateHome']

  // Furnitue
  const furnished = furniture === null ? [] : furniture ? ['furnished'] : ['unfurnished']

  // Rules
  const rules = [
    ...(pets || traits?.includes(HomeTraitEnum.pets) ? ['pets'] : []),
    ...(smoker || traits?.includes(HomeTraitEnum.smoker) ? ['smoker'] : []),
  ]

  // Home types
  const homeTypes = parseHomeTypes({ selectedHomeTypes: homeType })

  return {
    searchAreas: searchAreas || [],
    contractTypes,
    homeTypes,
    wheelchairAccessible:
      wheelchairAccessible || traits?.includes(HomeTraitEnum.wheelchair_accessible)
        ? ['wheelchairAccessible']
        : [],
    sharedHome,
    furnished,
    rules,
    minMonthlyCost,
    maxMonthlyCost,
    minRoomCount,
    maxRoomCount,
    minSquareMeters,
    maxSquareMeters,
    earliestMoveIn: moveInEarliest,
    minRentalLength,
    householdSize,
  }
}

type ParseHomeTypes = {
  selectedHomeTypes: HomeTypeEnum[] | null
}
const parseHomeTypes = ({ selectedHomeTypes }: ParseHomeTypes) => {
  const filteredHomeTypes = selectedHomeTypes
    ? selectedHomeTypes.filter((homeType) => FILTERABLE_HOME_TYPES.includes(homeType))
    : []
  return filteredHomeTypes
}

// Not entirely type safe since technically the object isn't guaranteed
// to not have other values in it
const getObjectKeys = <TObj extends Record<string, unknown>>(obj: TObj) => Object.keys(obj) as (keyof TObj)[]

type UseFormattedSavedSearchParams = {
  savedSearch: SavedSearchesQuery['savedSearch'][number]
}

export const useFormattedSavedSearch = ({ savedSearch }: UseFormattedSavedSearchParams) => {
  const parsedSavedSearch = parseSavedSearch({ searchParams: savedSearch.searchParams })
  return useFormattedFilterValues({ filterValues: parsedSavedSearch })
}

type UseFormattedFilterValuesParams = {
  filterValues: Partial<FindHomeFilterValues>
}
export const useFormattedFilterValues = ({ filterValues }: UseFormattedFilterValuesParams) => {
  const { t } = useAppTranslation('saved_searches')
  const currency = useRegionConfig().currency

  const {
    searchAreas,
    minRoomCount,
    maxRoomCount,
    minSquareMeters,
    maxSquareMeters,
    minMonthlyCost,
    maxMonthlyCost,
  } = filterValues

  let numberOfFilters = 0
  const savedSearchFilters = omit(
    filterValues,
    'searchAreas',
    'page',
    'order',
    'orderBy',
    'uids',
    'rentalType',
  )

  getObjectKeys(savedSearchFilters).forEach((filterKey) => {
    if (getIsFilterActive({ filterKey, currentValue: filterValues[filterKey] })) {
      numberOfFilters++
    }
  })

  let formattedAreas: string | null = ''
  formattedAreas = searchAreas?.[0]?.name || null

  if (!formattedAreas) {
    formattedAreas = t('no_areas')
  }

  // Some areas are missing their actual name due to legacy solutions.
  // It should get fixed as time goes and more names are saved, but in the meantime
  // they have a hard coded name so we can gracefully handle it in the frontend
  if (formattedAreas === 'unnamed') {
    t('missing_area_name')
  }

  const searchAreaCount = searchAreas?.length || 0

  if (searchAreaCount === 0) {
    formattedAreas = t('no_areas')
  } else if (searchAreaCount >= 2) {
    formattedAreas = t('areas_with_count', { areas: formattedAreas, count: searchAreaCount - 1 })
  }

  const formattedMinMaxRooms =
    minRoomCount && maxRoomCount
      ? minRoomCount === maxRoomCount
        ? t('commons:roomWithCount', { count: minRoomCount, ns: 'filter_sections' })
        : t('home_size.min_max_rooms', { minRoomCount, maxRoomCount, ns: 'filter_sections' })
      : null

  const formattedMinRooms =
    minRoomCount && t('home_size.min_rooms', { count: minRoomCount, ns: 'filter_sections' })
  const formattedMaxRooms =
    maxRoomCount === 1
      ? t('commons:roomWithCount', { count: 1, ns: 'filter_sections' })
      : maxRoomCount
        ? t('home_size.max_rooms', { count: maxRoomCount, ns: 'filter_sections' })
        : null

  const formattedRooms = formattedMinMaxRooms ?? formattedMinRooms ?? formattedMaxRooms ?? null

  const formattedSquareMeters =
    minSquareMeters && maxSquareMeters
      ? t('home_size.min_max_sqm', { minSquareMeters, maxSquareMeters, ns: 'filter_sections' })
      : minSquareMeters
        ? t('home_size.min_sqm', { size: minSquareMeters, ns: 'filter_sections' })
        : maxSquareMeters
          ? t('home_size.max_sqm', { size: maxSquareMeters, ns: 'filter_sections' })
          : null

  const formattedRentSpan =
    minMonthlyCost &&
    maxMonthlyCost &&
    t('rent.range', {
      min: formatNumber({ amount: minMonthlyCost, currency }),
      max: formatNumber({ amount: maxMonthlyCost, currency }),
      ns: 'filter_sections',
    })
  const formattedRentMin =
    minMonthlyCost &&
    t('rent.min', {
      min: formatNumber({ amount: minMonthlyCost, currency }),
      ns: 'filter_sections',
    })
  const formattedRentMax =
    maxMonthlyCost &&
    t('rent.max', {
      max: formatNumber({ amount: maxMonthlyCost, currency }),
      ns: 'filter_sections',
    })
  const formattedRent = formattedRentSpan ?? formattedRentMax ?? formattedRentMin ?? null

  const formattedSize = formattedRooms ?? formattedSquareMeters

  const formattedFilterCount = numberOfFilters
    ? t('filters_count', { count: numberOfFilters })
    : t('all_homes')
  // Only show filter count if no other filters are set
  const filterCount = formattedSize || formattedRent ? null : formattedFilterCount

  return [formattedAreas, formattedSize, formattedRent, filterCount].filter((item) =>
    Boolean(item),
  ) as string[]
}
