import type { Reference } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { styled, Button, Divider, Paragraph, Stack } from '@qasa/ui'
import type { SavedSearchesQuery } from '@qasa/graphql'
import { DELETE_SAVED_SEARCH } from '@qasa/app/src/features/find-home/search-history/saved-searches/saved-searches.gql'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { useToastContext } from '@qasa/app/src/contexts/toasts'
import { Dialog } from '@qasa/app/src/components/dialog'
import { SlashSeparatedData } from '@qasa/app/src/components/slash-separated-data'

import { useFormattedSavedSearch } from '../../saved-searches/saved-searches.utils'

const ButtonWrapper = styled(Stack)({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
})

type SavedSearchDeletionButtonAndDialogProps = {
  isOpen: boolean
  onOpenChange: (newValue: boolean) => void
  savedSearch: SavedSearchesQuery['savedSearch'][number]
}
export function DeleteSavedSearch({
  isOpen,
  onOpenChange,
  savedSearch,
}: SavedSearchDeletionButtonAndDialogProps) {
  const { t } = useAppTranslation('saved_searches')
  const { addToast } = useToastContext()
  const savedSearchSummary = useFormattedSavedSearch({ savedSearch })
  const { id, monitoring: isMonitoring } = savedSearch

  const [deleteSavedSearch] = useMutation(DELETE_SAVED_SEARCH, {
    onError: () => addToast({ message: t('commons:unexpected_error') }),
    update: (cache, { data }) => {
      const deletedSavedSearchId = data?.destroySavedSearch?.id
      cache.modify({
        fields: {
          savedSearch(existing: readonly Reference[] = [], { readField }) {
            return existing.filter(
              (savedSearchRef) => readField('id', savedSearchRef) !== deletedSavedSearchId,
            )
          },
        },
      })
    },
    optimisticResponse: {
      __typename: 'MutationRoot',
      destroySavedSearch: { __typename: 'DestroySavedSearchPayload', id },
    },
  })

  const handleConfirmPress = () => {
    deleteSavedSearch({ variables: { savedSearchId: id } })
    addToast({ message: t('delete.success_message', { title: savedSearchSummary.join(' / ') }) })
    onOpenChange(false)
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={onOpenChange} trigger={null}>
      <Dialog.Body>
        <Dialog.Header>
          <Dialog.Title>{t('delete.dialog_title')}</Dialog.Title>
        </Dialog.Header>
        <Stack gap="8x">
          <SlashSeparatedData gap="2x" data={savedSearchSummary} />
          {isMonitoring && (
            <>
              <Divider />
              <Paragraph>{t('delete.description_monitoring')}</Paragraph>
            </>
          )}
        </Stack>
      </Dialog.Body>
      <Dialog.Footer hasSidePadding>
        <ButtonWrapper>
          <Button onPress={() => onOpenChange(false)} variant="tertiary">
            {t('delete.close_button')}
          </Button>
          <Button onPress={handleConfirmPress}> {t('delete.confirm_button')}</Button>
        </ButtonWrapper>
      </Dialog.Footer>
    </Dialog>
  )
}
